
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { BlockBannerVideo } from '../../BlockBannerVideo';
import { Ref, toRef } from 'vue';

// variable
export default {
  name: 'BlockBannerVideo',
  mixins: [MixinProps, SerialLayoutInitialization],
  components:{
    ComponentContainer,
    ComponentWrapper,
  },
  setup(props: any, context: any){
    const refBlockBannerVideo = toRef(props, 'item') as Ref<BlockBannerVideo>;

    const { emitLayoutInitialized } = useSerialLayoutInitialization(refBlockBannerVideo.value, context);

    return {
      props,
      refBlockBannerVideo,
      emitLayoutInitialized,
    };
  }
}
