import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d411170"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "AppBarContainer Unselectable InheritSize",
  style: {"position":"relative","width":"100%","overflow":"hidden"}
}
const _hoisted_2 = { class: "TitleContainer" }
const _hoisted_3 = { class: "LinksContainer InheritSize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Contained = _resolveComponent("Contained")!
  const _component_ComponentContainer = _resolveComponent("ComponentContainer")!

  return (_openBlock(), _createBlock(_component_ComponentContainer, {
    item: $setup.refAppBar,
    context: $setup.refContext
  }, {
    body: _withCtx(() => [
      _createVNode(_component_Contained, {
        styleContained: $setup.refStyleContained,
        context: $setup.refContext
      }, {
        body: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_router_link, {
                class: "Title GlobalLink",
                style: _normalizeStyle($setup.getLinkButtonStyle()),
                to: {path: $setup.getPathForPage('/'), query: _ctx.$route.query}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.refAppBar.title.getValueOf($setup.refContext.languageOption)), 1)
                ]),
                _: 1
              }, 8, ["style", "to"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.refContext.selection.app.pageNodes.getElements(), (pageNode) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  class: "GlobalLink Link",
                  style: _normalizeStyle($setup.getLinkButtonStyle()),
                  key: pageNode.ref,
                  to: {path: $setup.getPathForPage(pageNode.url.getValue()), query: _ctx.$route.query}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(pageNode.titleNavigation.getValueOf($setup.refContext.languageOption)), 1)
                  ]),
                  _: 2
                }, 1032, ["style", "to"]))
              }), 128))
            ]),
            _createElementVNode("button", {
              class: "GlobalFontIconButton MenuButton",
              onClick: _cache[0] || (_cache[0] = ($event: any) => {$setup.refContext.uiState.requestMenuPopup(true);})
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: "bars",
                style: _normalizeStyle([{"font-size":"20px","text-align":"center","vertical-align":"middle"}, $setup.getMenuButtonStyle()])
              }, null, 8, ["style"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["styleContained", "context"])
    ]),
    _: 1
  }, 8, ["item", "context"]))
}