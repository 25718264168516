import { BaseItem, MetaInfo } from '@/components/shared/model/BaseItem';
import { Image } from '@/components/elemental/image/Image';
import { Button } from '@/components/elemental/button/Button';
import { Text } from '@/components/elemental/text/Text';
import { Location } from '@/components/elemental/location/Location';
import { ProductPreview } from '@/components/product/productPreview/ProductPreview';
import { BlockText } from '@/components/blocks/blockText/BlockText';
import { Spacer } from '@/components/elemental/spacer/Spacer';
import { Video } from '@/components/elemental/video/Video';
import { BlockBannerVideo } from '@/components/blocks/blockBannerVideo/BlockBannerVideo';
import { BlockBannerImage } from '@/components/blocks/blockBannerImage/BlockBannerImage';
import { CustomWidget } from '@/components/custom/customWidget/CustomWidget';
import { ProductGallery } from '@/components/product/productGallery/ProductGallery';
import { ImageGallery } from '@/components/elemental/imageGallery/ImageGallery';

export
interface SizeHint{
  width: number,  //*100%
  height: number, //px
}

export
interface ItemInfo{
  meta: MetaInfo;
  getInstance: ()=> BaseItem;
  sizeHint: SizeHint;
}

export
const getItemInfos = (): ItemInfo[] => {
  const list = [
    // {meta: Collection.meta, getInstance: ()=> new Collection(), sizeHint: {width: 0.5, height: 200}},
    // {meta: FluidContainer.meta, getInstance: ()=> new FluidContainer(), sizeHint: {width: 0.5, height: 200}},
    // {meta: AppBar.meta, getInstance: ()=> new AppBar(), sizeHint: {width: 0.5, height: 50}},
    // {meta: PageRef.meta, getInstance: ()=> new PageRef(), sizeHint: {width: 0.5, height: 200}},
    // {meta: PropertyString.meta, getInstance: ()=> new PropertyString(), sizeHint: {width: 0.5, height: 200}},
    // {meta: Product.meta, getInstance: ()=> new Product(), sizeHint: {width: 0.5, height: 200}},
    // {meta: ProductRef.meta, getInstance: ()=> new ProductRef(), sizeHint: {width: 0.5, height: 200}},
    {meta: ProductPreview.meta, getInstance: ()=> new ProductPreview(), sizeHint: {width: 6/12, height: 192}},
    {meta: ProductGallery.meta, getInstance: ()=> new ProductGallery(), sizeHint: {width: 1, height: 192}},
    {meta: Image.meta, getInstance: ()=> new Image(), sizeHint: {width: 10/12, height: 192}},
    {meta: ImageGallery.meta, getInstance: ()=> new ImageGallery(), sizeHint: {width: 10/12, height: 192}},
    {meta: Text.meta, getInstance: ()=> new Text(), sizeHint: {width: 10/12, height: 192}},
    {meta: Spacer.meta, getInstance: ()=> new Spacer(), sizeHint: {width: 0.5, height: 48}},
    {meta: Button.meta, getInstance: ()=> new Button(), sizeHint: {width: 4/12, height: 48}},
    {meta: Location.meta, getInstance: ()=> new Location(), sizeHint: {width: 0.5, height: 192}},
    {meta: Video.meta, getInstance: ()=> new Video(), sizeHint: {width: 0.5, height: 192}},
    {meta: CustomWidget.meta, getInstance: ()=> new CustomWidget(), sizeHint: {width: 0.5, height: 192}},
  ] as ItemInfo[];
  return list;
};

export
const findItemInfo = (typeName: string) : ItemInfo => {
  for (const info of getItemInfos()){
    if (info.meta.typeName == typeName){
      return info;
    }
  }
  throw new Error("Items findItemInfo: invalid type name.");
}

export
const getBlockInfos = (): ItemInfo[] => {
  const list = [
    {meta: BlockText.meta, getInstance: ()=> new BlockText(), sizeHint: {width: 1, height: 192}},
    {meta: BlockBannerVideo.meta, getInstance: ()=> new BlockBannerVideo(), sizeHint: {width: 1, height: 496}},
    {meta: BlockBannerImage.meta, getInstance: ()=> new BlockBannerImage(), sizeHint: {width: 1, height: 496}},
  ] as ItemInfo[];
  return list;
};