import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentWrapper = _resolveComponent("ComponentWrapper")!
  const _component_ComponentContainer = _resolveComponent("ComponentContainer")!

  return (_openBlock(), _createBlock(_component_ComponentContainer, {
    item: $setup.refBlockText,
    context: $setup.props.context
  }, {
    body: _withCtx(() => [
      _createVNode(_component_ComponentWrapper, {
        item: $setup.refBlockText.container,
        context: $setup.props.context,
        onLayoutInitialized: $setup.emitLayoutInitialized
      }, null, 8, ["item", "context", "onLayoutInitialized"])
    ]),
    _: 1
  }, 8, ["item", "context"]))
}