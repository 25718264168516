
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { BlockBannerImage } from '../../BlockBannerImage';
import { Ref, toRef } from 'vue';

// variable
export default {
  name: 'BlockBannerImage',
  mixins: [MixinProps, SerialLayoutInitialization],
  components:{
    ComponentContainer,
    ComponentWrapper,
  },
  setup(props: any, context: any){
    const refBlockBannerImage = toRef(props, 'item') as Ref<BlockBannerImage>;

    const { emitLayoutInitialized } = useSerialLayoutInitialization(refBlockBannerImage.value, context);

    return {
      props,
      refBlockBannerImage,
      emitLayoutInitialized,
    };
  }
}
