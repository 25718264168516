
import { ItemInfo, getBlockInfos } from '@/components/shared/registry/Items';
import { PropType, reactive, toRef, watch } from 'vue';
import Context from '@/components/shared/view/Context';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';

export default {
  name: 'BlockSelector',
  props:{
    onSelected: {
      type: Function,
      required: false,
      default: undefined,
    },
    context: {
      type: Object as PropType<Context>,
      required: true,
    },
  },
  components: {
    ComponentWrapper
  },
  setup(props: any) {
    const refContext = toRef(props, 'context');
    const refSelectorContext = reactive(new Context);
    watch(
      ()=>refContext.value.selection.app!.theme,
      ()=>{
        refSelectorContext.from(refContext.value);
        refSelectorContext.mode.selectItem = false;
        refSelectorContext.mode.editContent = false;
        refSelectorContext.mode.editLayout = false;
        refSelectorContext.mode.editTextInline = false;
      },
      {immediate: true, deep: true}
    );

    const findReflectionInfo = (typeName: string): ItemInfo => {
      for (const info of getBlockInfos()){
        if (info.meta.typeName == typeName){
          return info;
        }
      }
      throw new Error("BlockSelector.findReflectionInfo: invalid type name.");
    };
    const selectItem = (typeName: string) => {
      props.onSelected(findReflectionInfo(typeName));
    };

    return {
      refSelectorContext,
      getBlockInfos,
      selectItem
    }
  }
}
