import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bc5579d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Overview" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentWrapper = _resolveComponent("ComponentWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getBlockInfos(), (itemInfo) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "BlockView",
        key: itemInfo.meta,
        onClick: ()=>{$setup.selectItem(itemInfo.meta.typeName);}
      }, [
        _createVNode(_component_ComponentWrapper, {
          style: {"pointer-events":"none"},
          item: itemInfo.getInstance(),
          context: $setup.refSelectorContext
        }, null, 8, ["item", "context"])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}