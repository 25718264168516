import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c3e1666"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "refDomContainer",
  class: "InheritSize"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editor = _resolveComponent("Editor")!
  const _directive_resize = _resolveDirective("resize")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.computedShowEditor)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "HtmlContainer InheritSize",
          style: _normalizeStyle($setup.computedEditorContainerStyle)
        }, [
          _createVNode(_component_Editor, {
            "api-key": "2ijnfm7xsmz0b7e6sw905qtdp6ay5622380hyz6lnwkv0vgk",
            modelValue: $setup.refCurrentValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.refCurrentValue) = $event)),
            init: $setup.editorConfig
          }, null, 8, ["modelValue", "init"])
        ], 4))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "HtmlContainer InheritSize",
          innerHTML: $setup.refCurrentValue
        }, null, 8, _hoisted_2))
  ])), [
    [_directive_resize, $setup.onResized]
  ])
}