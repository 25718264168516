
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { Ref, toRef } from 'vue';
import { BlockText } from '../../BlockText';

// variable
export default {
  name: 'BlockText',
  mixins: [MixinProps, SerialLayoutInitialization],
  components:{
    ComponentContainer,
    ComponentWrapper,
  },
  setup(props: any, context: any){
    const refBlockText = toRef(props, 'item') as Ref<BlockText>;

    const { emitLayoutInitialized } = useSerialLayoutInitialization(refBlockText.value, context);

    return {
      props,
      refBlockText,
      emitLayoutInitialized,
    };
  }
}
