import { BaseItem, MetaInfo } from "./BaseItem";
import { BaseStyle } from "./BaseStyle";

export
abstract class BaseDefinition extends BaseItem{
  constructor (){
    super();
  }

  public getSubItems(): BaseItem[]{
    const subItems = [];
    for (const value of Object.values(this)) {
      if (value instanceof BaseItem){
        subItems.push(value);
      }
    }
    return subItems;
  }

  public getSubItemsWithoutStyle(): BaseItem[]{
    const subItems = [];
    for (const value of Object.values(this)) {
      if (value instanceof BaseItem && !(value instanceof BaseStyle)){
        subItems.push(value);
      }
    }
    return subItems;
  }

  public getSubItem(itemName: string): BaseItem | null{
    for (const subItem of this.getSubItems()){
      if (subItem.getItemName() == itemName)
        return subItem;
    }
    return null;
  }

  public getSubItemWithMeta(meta: MetaInfo): BaseItem | null{
    for (const subItem of this.getSubItems()){
      if (subItem.getMeta() == meta)
        return subItem;
    }
    return null;
  }

  public override from(item: BaseItem): void{
    super.from(item);
    // const subItems = this.getSubItems();
    // for (let i = 0; i<subItems.length; ++i){
    //   subItems[i].from((item as BaseDefinition).getSubItems()[i]);
    // }
  }

  public contains(item: BaseItem): BaseItem[]{
    const subItems = this.getSubItems();
    for (const subItem of subItems){
      if (subItem.ref == item.ref){
        return [subItem, this];
      }
    }
    for (const subItem of subItems){
      const tmp = subItem.contains(item);
      if (tmp.length>0){
        tmp.push(this);
        return tmp;
      }
    }
    return [];
  }
}